export default {
  computed: {
    theme () {
      const vueTheme = this.$vuetify.theme
      if (vueTheme.isDark) {
        return vueTheme.themes.dark
      }
      return vueTheme.themes.light
    },
    parentClass () {
      return this.$options._parentVnode.data.staticClass ?? this.$options._parentVnode.data.class
    },
  },
  methods: {
    // num2 => num1
    relativeChange (num1 = 0, num2 = 0) {
      console.log('relativeChange', num1, num2)
      if (num2 !== 0) {
        return Math.round((num1 - num2) / num2 * 100)
      } else {
        // return Math.round(num1 * 100)
        return null
      }
    },
    prepareDiagramLabels (data, obj) {
      var objArrVal = this.$_.map(data, obj)
      var diagramLabels = []
      objArrVal.forEach((val, index) => {
        const label = {
          count: val,
          start_period: data[index].date_start,
          end_period: data[index].date_end,
        }
        diagramLabels.push(label)
      })
      return diagramLabels
    },
    generateClassesByPrefix (mainClass, prefix) {
      const classes = mainClass.split(' ')
      let resClass = ''
      classes.forEach(classEl => {
        if (prefix !== undefined) resClass += classEl + prefix + ' '
        else resClass += classEl + ' '
      })
      return resClass.slice(0, -1)
    },
    declOfNum (number, titles) {
      number = Number(number)
      const cases = [2, 0, 1, 1, 1, 2]
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
    },
    prepareDecimalNumberToDisplay (v) {
      return v != null ? Number(v).toLocaleString() : ''
    },
    getPercentToDisplay (percent) {
      if (percent == null) return ''
      const percentStr = Number(percent).toLocaleString()
      return percent > 0 ? `+${percentStr}%` : `${percentStr}%`
    },
  },
}
